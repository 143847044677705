/* 2022/11/19 */
/* Sorawit: Update fonts and root css */
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,700;1,300;1,700&display=swap");

.card img {
    width: 100%;
    height: auto;
}

.card-body {
    padding: 0.5rem 0.5rem !important;
}

.card-img {
    /* height: 12rem !important; */
    height: 8rem !important;
}

.image {
    height: 100%;
}

.image img {
    height: 100%;
    width: 100%;
}

.card-title {
    height: 66px;
}

.card-title h2 .a_title {
    font-size: 16px;
    max-height: 66px;
    line-height: 22px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
}

.card-text-set {
    font-size: 0.7rem;
    display: flex;
    justify-content: space-between;
}

.card-text-info {
    color: #be1831;
    font-weight: bold;
}

.card-text-date {
    color: #8d8c8c;
}

.item-show {
    transition: transform 0.2s; /* Animation */
    color: #494949;
}

.item-show:hover {
    transform: scale(1.1);
    z-index: 10000;
}

.item-show .a_title:hover {
    color: #ce240d;
}

body {
    font-family: "Kanit";
}

:root {
    /* Header */
    /* --main-header: #05a44c;
    --text-header: #fcfcfc;
    --text-header-b: #047a38;
    --main-search-header: #ffffff; */

    /* Footer */
    --ft-detail-topic: black;
    --ft-detail-href: rgb(45, 45, 45);
    --ft-copyright-bg: black;
    --ft-copyright-text: white;
}

/* Footer */
.footer-detail-topic {
    color: var(--ft-detail-topic);
}

.footer-detail a {
    color: var(--ft-detail-href);
}

.footer-copyright {
    background-color: var(--ft-copyright-bg);
    color: var(--ft-copyright-text);
}

/* Tags */
.tags-input-container {
    /* border: 2px solid #000; */
    padding: 0.5em;
    border-radius: 3px;
    /* width: min(80vw, 600px); */
    margin-top: 1em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5em;
}

.tags-link .tags-input-container {
    color: black;
    text-decoration: none;
}
.tags-link:hover {
    color: #d8402a !important;
}
.tags-input-container span:before {
    content: "#";
}

.tag-item {
    /* background-color: rgb(218, 216, 216); */
    border-color: #d8402a;
    display: inline-block;
    padding: 0.5em 0.75em;
    border-radius: 20px;
    border: 1px solid red;
}
.tag-item .close {
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
    font-size: 18px;
    cursor: pointer;
}

.tags-input {
    flex-grow: 1;
    padding: 0.5em 0;
    border: none;
    outline: none;
}

.head-topic {
    margin-top: 1rem;
    color: black;
    text-decoration: none;
    font-weight: bold;
    font-size: 2rem;

    background-image: linear-gradient(120deg, #ce240d 0%, #f4f48f00 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.15em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
}

.head-topic:hover {
    /* background-size: 100% 88%; */
    color: black;
}

.TopMain {
    color: white;
    font-weight: bold;
    font-size: 2rem;
    width: 100%;
    background-color: black;
}

.NoContent {
    cursor: default;
    border-radius: 1rem;
    font-size: 1.3rem;
    background-color: #e7e7e7;
    display: grid;
    align-content: center;
    justify-content: center;
    line-height: 7em;
}

.button-to-top {
    position: fixed;
    width: 100%;
    bottom: 3rem;
    z-index: 999999999;
    display: grid;
    justify-content: end;
    padding-right: 1rem;
}

/* Provide sufficient contrast against white background */
a {
    color: black;
    text-decoration: none;
}

a:hover {
    color: #d8402a;
    text-decoration: none;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.showtext-slides {
    background-color: #0000005e;
    border-radius: 0.5rem;
    text-shadow: black 0.1em 0.1em 0.2em;
}

.mobile-Caption {
    position: inherit;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

.mobile-Caption:hover {
    color: #eb3017cb;
}

.textinslides {
    margin: 0.5vw 1.5vw 0.5vw 1.5vw;
    font-size: 3.5vw;
    max-height: 20vw;
    line-height: 5vw;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    text-align: left;
}

.carousel-indicators-mobile .carousel-indicators {
    margin-bottom: 0rem !important;
}

.container-new .image {
    display: grid;
    justify-content: center;
}

.container-new .image img {
    max-width: 65%;
    /* width: auto; */
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 767px) {
    .container-new .image img {
        max-width: 95%;
        /* width: auto; */
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.colorButtonSearch {
    color: #cb240b;
    border-color: #cb240b;
}
.colorButtonSearch:hover {
    color: wheat;
}

hr {
    margin: 0.5rem;
}

.classSetRedMenu .dropdown-toggle {
    color: #cc230b !important;
    font-weight: bold;
}

.notLink:hover {
    cursor: default;
    color: black;
}

.content_title {
    font-weight: bold;
    line-height: 1.5;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.classBread {
    --bs-breadcrumb-divider: ">";
}

.setResizeContentdetailProFile img {
    width: 100% !important;
    max-width: 100% !important;
    display: block !important;
    margin: 0 auto !important;
    height: auto !important;
    background-color: black !important;
}

.dateTimePrfile {
    justify-content: space-between;
    display: flex;
    margin-left: 1rem;
    margin-right: 1rem;
}

.sdbarText {
    color: white;
}

.sdbarText:hover {
    color: #cb240b;
    font-weight: bold;
}

.bgw {
    background-color: white !important;
}

/* Sorawit Add 2566-01-29 */
/* .cookie-policy-btn {
    display: none;
} */

.cookie-bubble {
    z-index: 999999999 !important;
}

.dropzone {
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    margin: 20px;
}

.dropzone-active {
    color: green;
}

.dropzone-inactive {
    color: #aaa;
}



.sharebutton {
    cursor: pointer;
    margin-left: 0.5rem !important;
}

.sharebutton:hover:not(:active) {
    opacity: 0.75;
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.centered {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}