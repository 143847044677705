/* ////////////////////////search tab//////////////////////// */
.searchtableend{
    width: 50px;
    border-color: #F97627;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-radius: 0px 5px 5px 0px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .searchtableend svg{
    color: #000;
  }

  .input-searchtable{
    max-width: 500px;
    width: 100%;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-radius: 5px 0px 0px 5px;
  }
  
  .input-searchtable:focus{
    border-color: #F97627;
    box-shadow: none;
  }

  @media (max-width: 1000px) {
    .input-searchtable{
      max-width: 300px;
      width: 100%;
    }
  }

/* ////////////////////////pagination//////////////////////// */

.pagination{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pagination-select{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.button-pagination{
    width: auto;
    /* height: 40px; */
    margin: 10px;
    /* border-radius: 20px; */
    border-color: #F97627;
    background-color: #fcad59;
    /* color: #F97627; */
}

.select-pagination{
    height: 38px;
    border-radius: 0.25rem;
}

.all-button-pagination button:not(:disabled){
    color: #FFFFFF;
}


/* ////////////////////////admin//////////////////////// */

.pagination-select-admin{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.searchtabadmin{
  margin: 0px;
  width: 100%;
  max-width: 175px;
}

.searchtableadminend{
  height: 25px;
  width: 25px;
  border-color: #130558;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-radius: 0px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.searchtabadmin input{
  border-color: #130558;
}

.searchtableadminend svg{
  color: #000;
}

.input-searchtableadmin{
  height: 25px;
  max-width: 150px;
  width: 100%;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-radius: 0px;
}

.input-searchtableadmin:focus{
  border-color: #130558;
  box-shadow: none;
}

@media (max-width: 1000px) {
  .input-searchtableadmin{
    max-width: 100px;
    width: 100%;
  }
}

.button-pagination-admin{
    width: auto;
    height: 40px;
    margin: 10px;
    border-color: #130558;
    background-color: #FFFFFF;
}

.all-button-pagination-admin button:not(:disabled){
    color: #5941D2;
}